import React from "react"
import { Link } from "gatsby"

import PageWrapper from "../components/PageWrapper"
import Section from "../components/Section"
import Button from "../components/Button"
import Container from "../components/Container"
import SectionTitle from "../components/SectionTitle"
import SectionText from "../components/SectionText"
import PageNotFoundImage1 from "../images/svg/page-not-found-1.svg"

const PageNotFound2 = () => {
  return (
    <>
      <PageWrapper
        footerConfig={{
          hasMiddleSocial: true,
          bottomWrapperClassName: "!justify-center",
          bottomClassName: "bg-primary-25",
          className: "bg-primary-25",
        }}
      >
        <Section isHero className="">
          <Container>
            {/* wrap */}
            <div className="flex flex-col items-center justify-center bg-white max-w-[560px] mx-auto  rounded-3xl px-6  tablet:px-14 laptop:px-[100px]  gap-14">
              {/* top-part */}
              <div className="">
                <PageNotFoundImage1 className="w-full h-auto tablet:w-auto"></PageNotFoundImage1>
              </div>
              {/* bottom-part */}
              <div className="flex flex-col items-center justify-center">
                <SectionTitle className="text-center">
                  Page not found!
                </SectionTitle>
                <SectionText
                  isHero={true}
                  className="max-w-[404px] text-center mx-auto"
                >
                  Sorry we’ve unable to find the page you’re looking for.
                </SectionText>
                <Link to="/home-3">
                  <Button className="" size={"2xl"}>
                    Go to home
                  </Button>
                </Link>
              </div>
            </div>
          </Container>
        </Section>
      </PageWrapper>
    </>
  )
}

export default PageNotFound2
